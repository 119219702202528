import { Container } from "components/container";
import { NavigationWrapperProps } from ".";
import { getEdgeSettings } from "..";
import { getSpeakingId } from "helpers/text-processing";
import HeadingWrapper from "../heading";
import Link from "next/link";
import { Shape } from "components/shape";
import { colors } from "theme/theme";
import { Cloudinary } from "components/cloudinary";

export default function AnchorLinkNavigation(props: NavigationWrapperProps) {
  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  const hasAnyImage = props.anchor_links?.some((link) => link.image);

  const content = (
    <ul className="anchor-links" data-columns={props.tiles_per_row || 4}>
      {props.anchor_links?.map((link, index) => (
        <li key={index} className="anchor-links__item">
          <Link
            href={`#${link.anchor_id}`}
            className="anchor-links__item__link"
          >
            {hasAnyImage && (
              <Cloudinary
                media={
                  link.image || {
                    digital_asset_id: "im-background/grey-triangle-16-9",
                  }
                }
                // width={330}
                // height={185}
                ar="ar169"
                fitStrategy="cover"
                className="anchor-links__item__link__image"
              />
            )}

            <div className="anchor-links__item__link__box">
              <div className="anchor-links__item__link__box__content">
                {link.icon && (
                  <Shape
                    variant={link.icon}
                    color={colors.white}
                    className="anchor-links__item__link__box__content__icon"
                  />
                )}
                <p className="anchor-links__item__link__box__content__title">
                  {link.title}
                </p>
              </div>

              <div className="anchor-links__item__link__box__icon">
                <Shape variant="caret-down-double" color={colors.white} />
                <Shape variant="caret-down-double" color={colors.white} />
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );

  return !props.fullwidth ? (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={
        props.custom_id || anchorNavId || `component__navigation-${props.index}`
      }
      className="component__navigation"
    >
      <>
        <HeadingWrapper {...props} />
        {content}
      </>
    </Container>
  ) : (
    content
  );
}
